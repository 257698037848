import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 8px;
		white-space: pre-wrap;
	}

	& p {
		padding: 0;
		margin: 0;
	}

	& ul {
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding: 0;
		margin: 0;
	}

	& li {
		margin-left: 24px;
	}
`;
export const HtmlParser = (p) => {
    var _a;
    const html = (_a = p.html) !== null && _a !== void 0 ? _a : "";
    return _jsx(Root, { dangerouslySetInnerHTML: { __html: html } }, void 0);
};
