var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonRegister } from "@/src/common/components/button/ButtonRegister";
import { Field } from "@/src/common/components/field/Field";
import { UserPasswordYup } from "@/src/common/utility/password/UserPassword";
import { getPhoneNumberFormatted } from "@/src/common/utility/phone/PhoneNumber";
import { usePhoneValidator } from "@/src/common/utility/phone/usePhoneValidator";
import { translate } from "@/src/common/utility/translation/Translate";
import { FormId } from "@/src/enums/forms";
import { Form } from "@/src/features/common";
import { FormikAddress } from "@/src/features/common/FormikAddress";
import { FormikTextFieldMaskedPhoneNumber } from "@/src/features/common/FormikTextFieldMaskedPhoneNumber";
import { PasswordStrengthIndicator } from "@/src/features/common/PasswordStrengthIndicator";
import { t } from "@/src/features/Localization";
import { RegisterType } from "@/src/features/RegisterAccount/types";
import { RegisterOrActivateTermsOfServiceCheckbox } from "@/src/features/RegisterOrPasswordOrActivation/RegisterOrActivateTermsOfServiceCheckbox";
import { FormikSelect } from "@/src/features/utils/Formik/FormikSelect";
import { FormikTextField } from "@/src/features/utils/Formik/FormikTextField";
import { FormikTextFieldTypes } from "@/src/features/utils/Formik/formikTypes";
import { useGroupViewListRegisterQuery } from "@/src/modules/group/redux/ApiCoreGroup";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { LoginAlternatives } from "@/src/modules/user/components/LoginAlernatives";
import { useUserRegisterPartnerMutation } from "@/src/redux/apiServices/suiteApi";
import { routes } from "@/src/routes";
import { parseJwt } from "@/src/utils/JwtUtils";
import { FormikProvider, useFormik } from "formik";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
const Root = styled.div `
	padding: 32px 0;
`;
const RootAlternatives = styled.div `
	& {
		margin: 32px 0;
	}
`;
export const FormInformation = (p) => {
    var _a, _b, _c, _d;
    const history = useHistory();
    const tokenRegister = p.tokenRegister;
    const claims = parseJwt(tokenRegister);
    const apiList = useGroupViewListRegisterQuery({});
    const provinceList = ((_b = (_a = apiList.data) === null || _a === void 0 ? void 0 : _a.data.provinceList) !== null && _b !== void 0 ? _b : []);
    const provinceDefault = (_c = apiList.data) === null || _c === void 0 ? void 0 : _c.data.provinceDefault;
    const pharmacy = (_d = apiList.data) === null || _d === void 0 ? void 0 : _d.data.pharmacy;
    const isPharmacy = Boolean(pharmacy);
    const phoneValidator = usePhoneValidator({});
    const [apiRegister, apiRegisterState] = useUserRegisterPartnerMutation();
    const formikInitialValues = {
        addressDelivery: { formatted: "" },
        nameFirst: claims.nameFirst || "",
        nameLast: claims.nameLast || "",
        phone: "",
        email: p.email,
        password: "",
        groupProvince: provinceDefault || "",
        privacyPolicy: false,
    };
    const formik = useFormik({
        initialValues: formikInitialValues,
        enableReinitialize: true,
        validateOnBlur: true,
        validationSchema: Yup.object().shape({
            addressDelivery: Yup.object().shape({
                formatted: Yup.string().min(1, "You must select an address").required("Required"),
            }),
            nameFirst: Yup.string()
                .min(1, t("common_nameInput_error_invalidMinLength"))
                .max(50, t("common_nameInput_error_invalidMaxLength", { maxLength: 50 }))
                .required(t("common_nameInput_error_invalidMinLength")),
            nameLast: Yup.string()
                .min(1, t("common_nameInput_error_invalidMinLength"))
                .max(50, t("common_nameInput_error_invalidMaxLength", { maxLength: 50 }))
                .required(t("common_nameInput_error_invalidMinLength")),
            phone: phoneValidator.schema,
            email: Yup.string().email(t("common_emailInput_error_invalidFormat")).required(t("common_emailInput_error_invalidFormat")),
            password: UserPasswordYup(),
            groupProvince: Yup.string().min(1, t("common_provinceInput_error_empty")).required(t("common_provinceInput_error_empty")),
            privacyPolicy: Yup.boolean().oneOf([true], "Required"),
        }),
        onSubmit: (v) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const response = (yield apiRegister({
                    tokenRegister: tokenRegister,
                    email: v.email,
                    password: v.password,
                    details: {
                        addressDelivery: v.addressDelivery.formatted,
                        nameFirst: v.nameFirst,
                        nameLast: v.nameLast,
                        phone: getPhoneNumberFormatted(v.phone),
                    },
                    group: v.groupProvince,
                }).unwrap());
                if (response.code == 200) {
                    history.push(routes.CheckEmail.toPath({
                        email: v.email,
                    }));
                }
            }
            catch (e) {
                // TODO: reidenzon - Do something!
                debugger;
                // const response = e.data as ApiResponse<UserRegisterPartnerResponseData>;
                // const result = response.data.result as UserRegisterPartnerResponseResult;
                // if (result == UserRegisterPartnerResponseResult.ExistsCheckEmail) {
                // 	history.push(
                // 		routes.LoginUserActivate.toPath({
                // 			email: v.email,
                // 		})
                // 	);
                // }
            }
        }),
    });
    return (_jsx(FormikProvider, Object.assign({ value: formik }, { children: _jsxs(Root, { children: [_jsx(LayoutContentHeader
                //
                , { 
                    //
                    title: "Create a free account", description: _jsx("span", { children: translate("You'll automatically be signed into your Mednow account from {institution} going forward.", {
                            institution: _jsx("b", { children: p.institution.details.name }, void 0),
                        }) }, void 0) }, void 0), _jsxs(Form, Object.assign({ onSubmit: formik.handleSubmit, id: FormId.ResetPassword }, { children: [_jsx(FormikTextField, { fullWidth: true, formikId: "nameFirst", label: t("signUp_email_step2_inputLabel_firstName") }, void 0), _jsx(FormikTextField, { fullWidth: true, formikId: "nameLast", label: t("signUp_email_step2_inputLabel_lastName") }, void 0), _jsx(FormikSelect, { isLoading: apiList.isLoading, formikId: "groupProvince", label: t("signUp_email_step1_inputLabel_province"), onChange: () => {
                                return null;
                            }, options: provinceList.map((g) => ({
                                value: g.guid,
                                label: g.name,
                            })) }, void 0), _jsx(FormikTextField, { disabled: true, fullWidth: true, formikId: "email", label: "Email", inputProps: {
                                autoComplete: "username",
                            } }, void 0), _jsx(FormikTextFieldMaskedPhoneNumber, { formikId: "phone", label: t("signUp_email_step2_inputLabel_phone") }, void 0), _jsxs(Field, { children: [_jsx(FormikTextField, { fullWidth: true, formikId: "password", type: FormikTextFieldTypes.Password, label: t("changePassword_inputLabel_newPassword") }, void 0), _jsx(PasswordStrengthIndicator, { currentPassword: formik.values.password }, void 0)] }, void 0), _jsx(FormikAddress, { formikId: "addressDelivery", label: "Address", placeholder: "Address" }, void 0), _jsx(RegisterOrActivateTermsOfServiceCheckbox, { registerType: RegisterType.Regular, formikId: "privacyPolicy" }, void 0), _jsx(ButtonRegister, { isLoading: apiRegisterState.isLoading, text: "Create Free Account" }, void 0)] }), void 0), _jsx(RootAlternatives, { children: _jsx(LoginAlternatives, { isLogin: true }, void 0) }, void 0)] }, void 0) }), void 0));
};
