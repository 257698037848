var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Drawer, DrawerAnchorEnum } from "@/src/features/common/Drawer";
import { getDeepLinkFromURLSearchParams, redirectToDeeplink } from "@/src/features/DeepLink/DeepLinkHelpers";
import { getJunctionPopupEventContent, isJunctionPopupEvent, JunctionPopupEventType } from "@/src/modules/junction/components/popup/JunctionPopupEventType";
import { getJunctionPopupSizeInfo } from "@/src/modules/junction/type/JunctionPopupSize";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
		//padding: 12px;
	}

	${(p) => p.$sizeInfo.Css};
`;
const RootFrame = styled.iframe `
	& {
		border: none;
		flex: 1;
		width: 100%;
	}
`;
export const JunctionPopupDrawer = (p) => {
    var _a;
    const isDismissible = (_a = p.isDismissible) !== null && _a !== void 0 ? _a : false;
    const history = useHistory();
    const sizeInfo = getJunctionPopupSizeInfo(p.size);
    const [isOpen, setIsOpen] = useState(true);
    const handleClose = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        if (!isDismissible) {
            return;
        }
        setIsOpen(false);
        (_b = p.onClose) === null || _b === void 0 ? void 0 : _b.call(p);
    });
    const handleMessage = (event) => {
        var _a, _b, _c, _d;
        // TODO: reidenzon - Add event.origin checks, limit to list!
        const m = event.data;
        if (isJunctionPopupEvent(m, JunctionPopupEventType.Close)) {
            setIsOpen(false);
            (_a = p.onClose) === null || _a === void 0 ? void 0 : _a.call(p);
            (_b = p.onDismiss) === null || _b === void 0 ? void 0 : _b.call(p);
        }
        if (isJunctionPopupEvent(m, JunctionPopupEventType.CloseWithoutDismiss)) {
            setIsOpen(false);
            (_c = p.onClose) === null || _c === void 0 ? void 0 : _c.call(p);
        }
        if (isJunctionPopupEvent(m, JunctionPopupEventType.NavigateDeeplink)) {
            const url = getJunctionPopupEventContent(m);
            const params = new URLSearchParams(url);
            const deeplink = getDeepLinkFromURLSearchParams(params);
            redirectToDeeplink(history, deeplink);
        }
        if (isJunctionPopupEvent(m, JunctionPopupEventType.NavigateUrl)) {
            const url = getJunctionPopupEventContent(m);
            window.open(url, "_blank");
            (_d = p.onClose) === null || _d === void 0 ? void 0 : _d.call(p);
        }
    };
    useEffect(() => {
        window.addEventListener("message", handleMessage);
        return () => window.removeEventListener("message", handleMessage);
    }, []);
    return (_jsx(Drawer
    //
    , Object.assign({ 
        //
        anchor: DrawerAnchorEnum.Bottom, open: isOpen, onClose: handleClose }, { children: _jsx(Root, Object.assign({ "$sizeInfo": sizeInfo }, { children: _jsx(RootFrame, { src: p.url }, void 0) }), void 0) }), void 0));
};
