import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable prefer-const */
import { ButtonNavigate } from "@/src/common/components/button/ButtonNavigate";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { usePhoneValidator } from "@/src/common/utility/phone/usePhoneValidator";
import { FormId } from "@/src/enums/forms";
import { Form } from "@/src/features/common";
import { FormikAddress } from "@/src/features/common/FormikAddress";
import { FormikTextFieldMaskedPhoneNumber } from "@/src/features/common/FormikTextFieldMaskedPhoneNumber";
import { FormikCheckbox } from "@/src/features/common/FormInputs";
import { t } from "@/src/features/Localization";
import FormikErrorFocus from "@/src/features/utils/Formik/FormikErrorFocus";
import { FormikTextField } from "@/src/features/utils/Formik/FormikTextField";
import { FormikProvider, useFormik } from "formik";
import styled from "styled-components";
import * as Yup from "yup";
const Root = styled.div `
	width: 500px;
	max-width: 100%;
`;
const RootTerms = styled.div `
	& {
		margin-top: 16px;
		margin-bottom: 32px;
	}
`;
const FormikTextFieldMaskStyled = styled(FormikTextFieldMaskedPhoneNumber) `
	width: 100%;
`;
export const RegisterPersonalInfoForm = (p) => {
    const state = p.state;
    const phoneValidator = usePhoneValidator({});
    const validationSchema = Yup.object().shape({
        addressDelivery: Yup.object().shape({
            formatted: Yup.string().min(1, "You must select an address").required("Required"),
        }),
        nameFirst: Yup.string()
            .min(1, t("common_nameInput_error_invalidMinLength"))
            .max(50, t("common_nameInput_error_invalidMaxLength", { maxLength: 50 }))
            .required(t("common_nameInput_error_invalidMinLength")),
        nameLast: Yup.string()
            .min(1, t("common_nameInput_error_invalidMinLength"))
            .max(50, t("common_nameInput_error_invalidMaxLength", { maxLength: 50 }))
            .required(t("common_nameInput_error_invalidMinLength")),
        phone: phoneValidator.schema,
        isCheckbox: Yup.boolean().oneOf([true], "Required"),
    });
    const formikInitialValues = Object.assign(Object.assign({}, state), { addressDelivery: { formatted: state.addressDelivery } });
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formikInitialValues,
        validateOnBlur: true,
        validationSchema: validationSchema,
        onSubmit: (v) => {
            p.onSubmit(Object.assign(Object.assign({}, v), { addressDelivery: v.addressDelivery.formatted }));
        },
    });
    return (_jsx(Root, { children: _jsx(FormikProvider, Object.assign({ value: formik }, { children: _jsxs(Form, Object.assign({ onSubmit: formik.handleSubmit, id: FormId.RegisterPersonalInfo }, { children: [_jsx(FormikTextField, { fullWidth: true, formikId: "nameFirst", label: t("signUp_email_step2_inputLabel_firstName") }, void 0), _jsx(FormikTextField, { fullWidth: true, formikId: "nameLast", label: t("signUp_email_step2_inputLabel_lastName") }, void 0), _jsx("div", Object.assign({ "data-cy": "register-phone-number" }, { children: _jsx(FormikTextFieldMaskStyled, { formikId: "phone", label: t("signUp_email_step2_inputLabel_phone") }, void 0) }), void 0), _jsx(FormikAddress, { formikId: "addressDelivery", label: "Address", placeholder: "Address" }, void 0), _jsx(RootTerms, Object.assign({ "data-cy": "privacy-policy-checkbox" }, { children: _jsx(FormikCheckbox, { formikId: "isCheckbox", label: t("signUp_email_step2_agreementOne", {
                                privacyPolicy: (_jsx(ButtonNavigate, { style: ButtonStyle.TextAnchor, pathTo: "https://mednow.ca/pages/privacy-policy", isNewTab: true, content: t("signUp_email_step2_agreementOne_privacyPolicy") }, void 0)),
                                termsOfService: (_jsx(ButtonNavigate, { style: ButtonStyle.TextAnchor, pathTo: "https://mednow.ca/pages/terms-of-use", isNewTab: true, content: t("signUp_email_step2_agreementOne_termsOfService") }, void 0)),
                            }), isSecondary: true }, void 0) }), void 0), _jsx(ButtonStandard
                    //
                    , { 
                        //
                        style: ButtonStyle.SolidPrimary, content: "Sign Up", iconRight: "person", isSubmit: true, isWide: true }, void 0), _jsx(FormikErrorFocus, {}, void 0)] }), void 0) }), void 0) }, void 0));
};
