import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonNavigate } from "@/src/common/components/button/ButtonNavigate";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { Field } from "@/src/common/components/field/Field";
import { FieldList } from "@/src/common/components/field/FieldList";
import { generateGuid } from "@/src/common/utility/guid/GUID";
import { Footer } from "@/src/features/common/Footer";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { JunctionBanners } from "@/src/modules/junction/components/banner/JunctionBanners";
import { JunctionPopup } from "@/src/modules/junction/components/popup/JunctionPopup";
import { JunctionScreen } from "@/src/modules/junction/type/JunctionScreen";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { useWorkflowModalUrgent } from "@/src/modules/workflow/layout/workflow/modal/WorkflowModalUrgent";
import { FormikRxList } from "@/src/modules/workflow/type/rx-refill-v3/components/draft/rx-list/FormikRxList";
import { routes } from "@/src/routes";
import { FormikProvider, useFormik } from "formik";
import styled from "styled-components";
import * as Yup from "yup";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 32px;
	}
`;
export const WorkflowDraftStep00Select = (p) => {
    const state = p.state;
    const workflow = p.workflow;
    const modalUrgent = useWorkflowModalUrgent();
    const formikId = generateGuid();
    const formikInitialValues = {
        rxNumberList: state.rxNumberList,
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formikInitialValues,
        validateOnBlur: true,
        validationSchema: Yup.object().shape({
            // TODO: reidenzon - Finish this!
            rxNumberList: Yup.array().min(1, "You must select at least one medication"),
        }),
        onSubmit: (v) => {
            modalUrgent.doModal({
                onNext: () => {
                    p.onNext(Object.assign(Object.assign({}, state), { rxNumberList: v.rxNumberList }));
                },
            });
        },
    });
    return (_jsxs(FormikProvider, Object.assign({ value: formik }, { children: [_jsx(PageOverflowContainer, Object.assign({ isFadeFooter: true }, { children: _jsx(PageWrapper, Object.assign({ isLargeVerticalPadding: true }, { children: _jsxs(Root, { children: [_jsx(JunctionBanners, { screen: JunctionScreen.WorkflowRxRefillDraftStep01Select }, void 0), _jsx(JunctionPopup, { screen: JunctionScreen.WorkflowRxRefillDraftStep01Select }, void 0), _jsx(LayoutContentHeader, { isMargin: false, title: "Available refills" }, void 0), _jsx("form", Object.assign({ id: formikId, onSubmit: formik.handleSubmit }, { children: _jsxs(FieldList, { children: [_jsx(Field, { children: _jsx(FormikRxList
                                            //
                                            , { 
                                                //
                                                formikId: "rxNumberList", rxList: state.rxList, workflow: workflow }, void 0) }, void 0), _jsx(Field, { children: _jsx(ButtonNavigate, { style: ButtonStyle.TextAnchor, content: "Don't have a prescription?", pathTo: routes.PrescriptionAlternatives.toPath({
                                                    patientGuid: workflow.patient.guid,
                                                }) }, void 0) }, void 0)] }, void 0) }), void 0)] }, void 0) }), void 0) }), void 0), _jsxs(Footer, { children: [_jsx(ButtonStandard, { style: ButtonStyle.SolidGhost, content: "Back", onClick: p.onBack, isWide: true }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, content: "Next", onClick: formik.handleSubmit, isSubmit: true, isWide: true }, void 0)] }, void 0)] }), void 0));
};
