import { jsx as _jsx } from "react/jsx-runtime";
import { OrderCard } from "@/src/features/Workflows/Orders/OrderCard";
import styled from "styled-components";
const Root = styled.div `
	display: flex;
	flex-direction: column;
	gap: 12px;
`;
export const OrderCards = ({ workflows, isPendingWorkflow, handleOnCardClick }) => {
    return (_jsx(Root, { children: workflows.map((workflow) => {
            return (_jsx(OrderCard
            //
            , { workflow: workflow, isPendingWorkflow: isPendingWorkflow, handleOnCardClick: handleOnCardClick }, workflow.guid));
        }) }, void 0));
};
