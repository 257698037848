import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonNavigate } from "@/src/common/components/button/ButtonNavigate";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { Field } from "@/src/common/components/field/Field";
import { FieldList } from "@/src/common/components/field/FieldList";
import { GridRow } from "@/src/common/components/grid/GridRow";
import { generateGuid } from "@/src/common/utility/guid/GUID";
import { getPhoneNumberFormatted } from "@/src/common/utility/phone/PhoneNumber";
import { translate } from "@/src/common/utility/translation/Translate";
import { Footer } from "@/src/features/common/Footer";
import { FormikImageUploader } from "@/src/features/common/ImageUploader/FormikImageUploader";
import { ImageTypeEnum } from "@/src/features/common/ImageUploader/types";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { t } from "@/src/features/Localization";
import { JunctionBanners } from "@/src/modules/junction/components/banner/JunctionBanners";
import { JunctionPopup } from "@/src/modules/junction/components/popup/JunctionPopup";
import { JunctionScreen } from "@/src/modules/junction/type/JunctionScreen";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { getSettingDetailsByType } from "@/src/modules/setting/type/SettingDetails";
import { SettingType } from "@/src/modules/setting/type/SettingType";
import { getUserDetailsNameFull } from "@/src/modules/user/type/UserDetails";
import { useWorkflowModalUrgent } from "@/src/modules/workflow/layout/workflow/modal/WorkflowModalUrgent";
import { routes } from "@/src/routes";
import { FormikProvider, useFormik } from "formik";
import styled from "styled-components";
import * as Yup from "yup";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 32px;
	}
`;
export const WorkflowDraftStep00Upload = (p) => {
    const state = p.state;
    const workflow = p.workflow;
    const patient = workflow.patient;
    const settingInfo = getSettingDetailsByType(SettingType.GroupPharmacyInfo, patient.settings);
    const modalUrgent = useWorkflowModalUrgent();
    const formikId = generateGuid();
    const formikInitialValues = {
        rxRequestFiles: state.rxRequestFiles,
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formikInitialValues,
        validateOnBlur: true,
        validationSchema: Yup.object().shape({
            rxRequestFiles: Yup.array().min(1, "You must select at least one image").max(10, "You can only select a maximum of 10 images").required("Required"),
        }),
        onSubmit: (v) => {
            modalUrgent.doModal({
                onNext: () => {
                    p.onNext(Object.assign(Object.assign({}, state), { rxRequestFiles: v.rxRequestFiles }));
                },
            });
        },
    });
    return (_jsxs(FormikProvider, Object.assign({ value: formik }, { children: [_jsx(PageOverflowContainer, Object.assign({ isFadeFooter: true }, { children: _jsx(PageWrapper, Object.assign({ isLargeVerticalPadding: true }, { children: _jsxs(Root, { children: [_jsx(JunctionBanners, { screen: JunctionScreen.WorkflowRxFillDraftStep01Upload }, void 0), _jsx(JunctionPopup, { screen: JunctionScreen.WorkflowRxFillDraftStep01Upload }, void 0), _jsx(LayoutContentHeader, { isMargin: false, title: translate("Upload new prescription(s) for {name}.", {
                                    name: getUserDetailsNameFull(workflow.patient.details),
                                }), description: _jsxs(_Fragment, { children: [_jsx("div", { children: "Start by uploading a clear photo of your prescription to get started right away. For future refills, we recommend having your doctor fax prescriptions directly to us - it's the fastest way to process your order." }, void 0), _jsx(GridRow, { children: translate("Fax Number: {fax}", {
                                                fax: _jsx("b", { children: getPhoneNumberFormatted(settingInfo.fax) }, void 0),
                                            }) }, void 0)] }, void 0) }, void 0), _jsx("form", Object.assign({ id: formikId, onSubmit: formik.handleSubmit }, { children: _jsxs(FieldList, { children: [_jsx(Field, { children: _jsx(FormikImageUploader, { formikId: "rxRequestFiles", imageType: ImageTypeEnum.RxRequestFiles, title: t("newRxFill_step1_subheader_prescriptionImages"), description: t("newRxFill_step1_description_prescriptionImages"), isFullWidth: true, maxImageNumber: 5 }, void 0) }, void 0), _jsx(Field, { children: _jsx(ButtonNavigate, { style: ButtonStyle.TextAnchor, content: "Don't have a prescription?", pathTo: routes.PrescriptionAlternatives.toPath({
                                                    patientGuid: workflow.patient.guid,
                                                }) }, void 0) }, void 0)] }, void 0) }), void 0)] }, void 0) }), void 0) }), void 0), _jsxs(Footer, { children: [_jsx(ButtonStandard, { style: ButtonStyle.SolidGhost, content: "Back", onClick: p.onBack, isWide: true }, void 0), _jsx(ButtonStandard, { content: "Next", form: formikId, isSubmit: true, isWide: true }, void 0)] }, void 0)] }), void 0));
};
