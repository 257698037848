import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
/**
 * This file is used strictly for local testing to visualize
 * the icons to help ensure they are behaving the way that is
 * intended.
 *
 * Note not all images are meant to be colored, or resized.
 * So it will be up to the developer to ensure the icons
 * are displayed, colorized, or resized as intended.
 *
 * You may see it when running locally by visiting:
 *
 * http://localhost:1800/app/test-routes/test-icons
 *
 */
import * as Icons from "@/src/features/common/Icons";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { useState } from "react";
import styled from "styled-components";
/* --------------------------------- Styles --------------------------------- */
const IconsTable = styled.table `
	&,
	th,
	td {
		border: 1px solid black;
	}

	th {
		min-width: 70px;
	}
`;
IconsTable.displayName = "IconsTable";
/* -------------------------------- Variables ------------------------------- */
const iconNames = Object.keys(Icons);
export const TestIconsPage = () => {
    const [filter, setFilter] = useState("");
    const filteredIconNames = iconNames.filter((iconName) => filter.trim() === "" || iconName.toLowerCase().indexOf(filter.toLowerCase()) >= 0);
    const testCases = [
        {},
        { size: 120 },
        { color: "green" },
        { color: "blue", size: 12 },
        { color: "purple", size: 24 },
        { color: "red", size: 48 },
        { color: "orange", size: 100 },
    ];
    const IconByName = ({ name }) => {
        const TempIcon = Icons === null || Icons === void 0 ? void 0 : Icons[name];
        if (!TempIcon) {
            return _jsxs("div", { children: [name, " : no result"] }, void 0);
        }
        return (_jsxs("tr", { children: [_jsx("td", { children: name }, void 0), testCases.map((iconProps, i) => (_jsx("td", { children: _jsx(TempIcon, Object.assign({}, iconProps), void 0) }, i)))] }, void 0));
    };
    function AllIcons() {
        const header = (_jsxs("tr", { children: [_jsx("th", { children: "Icon Name" }, void 0), testCases.map((iconProps, i) => (_jsxs("th", { children: [_jsx("div", { children: iconProps === null || iconProps === void 0 ? void 0 : iconProps.color }, void 0), _jsx("div", { children: iconProps === null || iconProps === void 0 ? void 0 : iconProps.size }, void 0)] }, i)))] }, void 0));
        return (_jsxs(IconsTable, { children: [header, filteredIconNames.map((name) => (_jsx(IconByName, { name: name }, name)))] }, void 0));
    }
    return (_jsx(PageContainer, { children: _jsx(PageOverflowContainer, { children: _jsxs(PageWrapper, { children: [_jsx("h1", { children: "Test Icons" }, void 0), _jsxs("div", { children: ["Filter: ", _jsx("input", { type: "text", value: filter, onChange: (e) => setFilter(e.target.value) }, void 0)] }, void 0), _jsx(AllIcons, {}, void 0)] }, void 0) }, void 0) }, void 0));
};
