import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { t } from "@/src/features/Localization";
import { useProfile } from "@/src/features/Profile/useProfile";
import { useCustomSnackbar } from "@/src/features/utils/CustomSnackbar";
import { FormikGenericEditField } from "@/src/features/utils/Formik/FormikGenericEditField";
import { FormikEditFieldType } from "@/src/features/utils/Formik/formikTypes";
import { JunctionBanners } from "@/src/modules/junction/components/banner/JunctionBanners";
import { JunctionPopup } from "@/src/modules/junction/components/popup/JunctionPopup";
import { JunctionScreen } from "@/src/modules/junction/type/JunctionScreen";
import { useUserEditMutation } from "@/src/modules/user/redux/ApiCoreUser";
import { useEffect } from "react";
import styled from "styled-components";
import * as Yup from "yup";
const ProfileAllergiesContentContainer = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 24px;
		padding: 24px 0;
	}
`;
const EditFieldContainer = styled.div ``;
export const ProfileAllergies = () => {
    const { profileUser: user, refetchProfile } = useProfile();
    const { snackbar } = useCustomSnackbar();
    const [postUserEditDetails, { error: postUserEditDetailsError, isSuccess: postUserEditDetailsSuccess }] = useUserEditMutation();
    function handleProfileAllergiesChangeSubmit(formValues) {
        const userProfileDetails = {};
        if (formValues.ProfileAllergiesInput && formValues.ProfileAllergiesInput.length > 0) {
            const allergyData = {
                notes: formValues.ProfileAllergiesInput,
                isHave: true,
            };
            userProfileDetails.healthAllergies = allergyData;
        }
        if (formValues.ProfileAllergiesInputCheckbox) {
            const allergyData = {
                notes: "",
                isHave: false,
            };
            userProfileDetails.healthAllergies = allergyData;
        }
        postUserEditDetails({
            guid: user === null || user === void 0 ? void 0 : user.guid,
            details: userProfileDetails,
        });
    }
    /* -------------------------------- Variables ------------------------------- */
    const userDetails = user === null || user === void 0 ? void 0 : user.details;
    const healthAllergies = userDetails === null || userDetails === void 0 ? void 0 : userDetails.healthAllergies;
    const hasHealthAllergies = healthAllergies === null || healthAllergies === void 0 ? void 0 : healthAllergies.isHave;
    /* ------------------------------ Formik Setup ----------------------------- */
    const ProfileAllergiesInputValidationSchema = Yup.object().shape({
        ProfileAllergiesInput: Yup.string().when("ProfileAllergiesInputCheckbox", {
            is: (patientAllergiesCheckbox) => patientAllergiesCheckbox === false,
            then: () => Yup.string().required(t("profile_tab_allergies_errorMessage")),
            otherwise: () => Yup.string(),
        }),
    });
    /* ------------------------------- useEffects ------------------------------- */
    useEffect(() => {
        if (postUserEditDetailsSuccess) {
            refetchProfile();
            snackbar.success(t("profile_message_profileInfoSaved"));
        }
    }, [postUserEditDetailsSuccess]);
    useEffect(() => {
        if (postUserEditDetailsError) {
            snackbar.error("Could not update profile field.");
        }
    }, [postUserEditDetailsError]);
    /* ---------------------------- Render Component ---------------------------- */
    return (_jsx(PageContainer, { children: _jsxs(ProfileAllergiesContentContainer, { children: [_jsx(JunctionBanners, { screen: JunctionScreen.ProfileAllergies }, void 0), _jsx(JunctionPopup, { screen: JunctionScreen.ProfileAllergies }, void 0), _jsx(EditFieldContainer, { children: _jsx(FormikGenericEditField, { editFieldType: FormikEditFieldType.TextArea, value: healthAllergies ? healthAllergies.notes : "", label: t("profile_tab_allergies_subheader_allergies"), placeholderText: t("profile_tab_allergies_inputLabel_hint_allergies"), formikId: "ProfileAllergiesInput", helperText: "Please share any and all food, drug or other allergies.", textAreaData: healthAllergies, checkBoxText: "Patient does not have allergies", checkBoxFormikId: "ProfileAllergiesInputCheckbox", checkBoxValue: hasHealthAllergies, handleSubmit: handleProfileAllergiesChangeSubmit, validationSchema: ProfileAllergiesInputValidationSchema }, void 0) }, void 0)] }, void 0) }, void 0));
};
