var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { DotStepper } from "@/src/common/components/dot-stepper/DotStepper";
import { JunctionHomeCardsItem } from "@/src/modules/junction/components/home/JunctionHomeCardsItem";
import { useJunctionHomeCardDismissMutation, useJunctionHomeCardsQuery } from "@/src/modules/junction/redux/ApiJunction";
import { useApiJunctionRequest } from "@/src/modules/junction/redux/ApiJunctionRequest";
import { useEffect, useState } from "react";
import styled from "styled-components";
const width = 375;
const Root = styled.div `
	& {
		background: ${(p) => p.theme.cardBackground};
		border: 1px solid ${(p) => p.theme.divider};
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		width: ${width}px;
	}
`;
const RootRow = styled.div `
	& {
		overflow-x: hidden;
	}
`;
const RootSlide = styled.div `
	& {
		align-items: stretch;
		display: flex;
		flex-direction: row;
		position: relative;
		transition-duration: 200ms;
		transition-property: all;
	}
`;
const RootContent = styled.div `
	& {
		display: flex;
		min-width: ${width}px;
		width: ${width}px;
	}
`;
const RootFooter = styled.div `
	& {
		align-items: center;
		border-top: 1px solid ${(p) => p.theme.divider};
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin: 0 24px;
		padding: 12px 0;
	}
`;
export const JunctionHomeCards = (p) => {
    var _a;
    const junctionRequest = useApiJunctionRequest();
    const apiList = useJunctionHomeCardsQuery(junctionRequest({}));
    let itemList = (((_a = apiList.data) === null || _a === void 0 ? void 0 : _a.data.items) || []);
    const stepMax = itemList.length - 1;
    const [step, setStep] = useState(0);
    const isFooter = itemList.length > 0;
    const [apiDismiss, apiDismissState] = useJunctionHomeCardDismissMutation();
    const isLoading = apiList.isLoading || apiList.isFetching;
    const handleDismiss = (item) => __awaiter(void 0, void 0, void 0, function* () {
        const response = (yield apiDismiss({
            cardId: item.id,
        }).unwrap());
        apiList.refetch();
    });
    const handleStep = (s) => {
        if (s < 0) {
            handleStep(s + itemList.length);
            return;
        }
        if (s > stepMax) {
            handleStep(s - itemList.length);
            return;
        }
        setStep(s);
    };
    useEffect(() => {
        step > 0 && handleStep(step);
    }, [itemList.length]);
    if (!itemList.length) {
        return null;
    }
    return (_jsxs(Root, { children: [_jsx(RootRow, { children: _jsx(RootSlide, Object.assign({ style: { left: -step * width } }, { children: itemList.map((item, index) => {
                        return (_jsx(RootContent, { children: _jsx(JunctionHomeCardsItem
                            //
                            , { 
                                //
                                isDismissing: apiDismissState.isLoading, item: item, onDismiss: handleDismiss }, void 0) }, item.id));
                    }) }), void 0) }, void 0), isFooter && (_jsxs(RootFooter, { children: [_jsx(ButtonStandard
                    //
                    , { 
                        //
                        style: ButtonStyle.TextSubtle, 
                        // content={"Prev"}
                        iconLeft: "chevron_left", onClick: () => handleStep(step - 1) }, void 0), _jsx(DotStepper, { step: step, steps: itemList.length, onStep: (s) => handleStep(s) }, void 0), _jsx(ButtonStandard
                    //
                    , { 
                        //
                        style: ButtonStyle.TextSubtle, 
                        // content={"Next"}
                        iconRight: "chevron_right", onClick: () => handleStep(step + 1) }, void 0)] }, void 0))] }, void 0));
};
