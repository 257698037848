var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AdvisoryBox } from "@/src/common/components/advistory/AdvisoryBox";
import { AdvisoryStyle } from "@/src/common/components/advistory/AdvisoryStyle";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { ButtonTelephone } from "@/src/common/components/button/ButtonTelephone";
import { Field } from "@/src/common/components/field/Field";
import { FieldError } from "@/src/common/components/field/FieldError";
import { FieldList } from "@/src/common/components/field/FieldList";
import { AddressPostalCodeYup } from "@/src/common/utility/address/AddressPostalCode";
import { getPhoneNumberFormatted } from "@/src/common/utility/phone/PhoneNumber";
import { usePhoneValidator } from "@/src/common/utility/phone/usePhoneValidator";
import { translate } from "@/src/common/utility/translation/Translate";
import { AssistanceLink } from "@/src/features/common/AssistanceLink";
import { Footer } from "@/src/features/common/Footer";
import { FormikAddress } from "@/src/features/common/FormikAddress";
import { FormikTextFieldMaskedPhoneNumber } from "@/src/features/common/FormikTextFieldMaskedPhoneNumber";
import { FormikRadioButtons } from "@/src/features/common/FormInputs/FormikRadioButtons";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { t } from "@/src/features/Localization";
import { FormikTextarea } from "@/src/features/utils/Formik/FormikTextarea";
import { FormikTextField } from "@/src/features/utils/Formik/FormikTextField";
import { DeliveryMethodSelectList } from "@/src/modules/devliery-method/components/select/DeliveryMethodSelectList";
import { GroupType } from "@/src/modules/group/type/GroupType";
import { JunctionBanners } from "@/src/modules/junction/components/banner/JunctionBanners";
import { JunctionPopup } from "@/src/modules/junction/components/popup/JunctionPopup";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { FulfillmentPharmacy } from "@/src/modules/workflow/layout/workflow/draft/fulfillment/FulfillmentPharmacy";
import { useWorkflowModalAddress } from "@/src/modules/workflow/layout/workflow/modal/WorkflowModalAddress";
import { useWorkflowModalRxOriginal } from "@/src/modules/workflow/layout/workflow/modal/WorkflowModalRxOriginal";
import { useLazyWorkflowDeliveryMethodViewListQuery } from "@/src/modules/workflow/redux/ApiCoreWorkflow";
import { getDeliveryMethodDescription, getDeliveryMethodIsAvailability, } from "@/src/modules/workflow/redux/contracts/WorkflowDeliveryMethodViewList";
import { getWorkflowFulfillmentInfo, WorkflowFulfillment } from "@/src/modules/workflow/type/WorkflowFulfillment";
import { resolveErrorTouched } from "@/src/utils/PathValue";
import { FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { boolean, object, string } from "yup";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 32px;
	}
`;
export const WorkflowStepFulfillment = (p) => {
    var _a;
    const state = p.state;
    const workflow = p.workflow;
    const group = workflow.group;
    const isPharmacy = group.type == GroupType.Pharmacy;
    const phoneValidator = usePhoneValidator({});
    const settings = p.workflow.patient.settings;
    // TODO: reidenzon - What is this for... to limit start/end slots?!
    // const settingDelivery = getSettingDetailsByType(SettingType.GroupDelivery, settings) as SettingDetailsGroupDelivery;
    const modalRxOriginal = useWorkflowModalRxOriginal();
    const modalAddress = useWorkflowModalAddress();
    const formikInitialValues = {
        agentPhone: state.agentPhone,
        deliveryAddress: { formatted: state.deliveryAddress },
        deliveryAddressNotes: state.deliveryAddressNotes,
        deliveryAddressPostalCode: state.deliveryAddressPostalCode,
        deliveryMethod: state.deliveryMethod,
        deliverySignatureNotes: state.deliverySignatureNotes,
        fulfillment: state.fulfillment,
        isPharmacy: isPharmacy,
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formikInitialValues,
        validateOnBlur: true,
        validationSchema: object().shape({
            agentPhone: string().when("fulfillment", {
                is: WorkflowFulfillment.Delivery,
                then: () => string().when({
                    is: () => Boolean(p.isFieldAgentPhone),
                    then: () => phoneValidator.schema,
                }),
            }),
            deliveryAddress: object().when("fulfillment", {
                is: WorkflowFulfillment.Delivery,
                then: () => object().shape({
                    formatted: string().min(1, "You must select an address to deliver to").required("Required"),
                }),
            }),
            deliveryAddressPostalCode: string().when("fulfillment", {
                is: WorkflowFulfillment.Delivery,
                then: () => AddressPostalCodeYup(),
            }),
            deliveryMethod: object().when("fulfillment", {
                is: WorkflowFulfillment.Delivery,
                then: () => object().required("Select a shipping option."),
            }),
            deliverySignatureNotes: string().when("fulfillment", {
                is: WorkflowFulfillment.Delivery,
                then: () => string().when("deliveryMethod", {
                    is: (v) => getDeliveryMethodIsAvailability(v),
                    then: () => string().required("Let us know the best time to deliver your medication."),
                    otherwise: () => string(),
                }),
            }),
            fulfillment: string(),
            isPharmacy: boolean().when("fulfillment", {
                is: WorkflowFulfillment.Pickup,
                then: () => boolean().isTrue(""),
            }),
        }),
        onSubmit: (v) => {
            var _a;
            if (v.fulfillment == WorkflowFulfillment.Delivery) {
                modalAddress.doModal({
                    state: {
                        deliveryAddress: v.deliveryAddress.formatted,
                        deliveryAddressNotes: (_a = v.deliveryAddressNotes) === null || _a === void 0 ? void 0 : _a.trim(),
                    },
                    onSetDefault: (s) => {
                        var _a;
                        p.onNext({
                            agentPhone: getPhoneNumberFormatted(v.agentPhone),
                            deliveryAddress: v.deliveryAddress.formatted,
                            deliveryAddressNotes: s.deliveryAddressNotes,
                            deliveryAddressPostalCode: v.deliveryAddressPostalCode,
                            deliveryAddressIsDefault: true,
                            deliveryMethod: v.deliveryMethod,
                            deliverySignatureNotes: (_a = v.deliverySignatureNotes) === null || _a === void 0 ? void 0 : _a.trim(),
                            fulfillment: v.fulfillment,
                        });
                    },
                    onThisOrder: (s) => {
                        var _a;
                        p.onNext({
                            agentPhone: getPhoneNumberFormatted(v.agentPhone),
                            deliveryAddress: v.deliveryAddress.formatted,
                            deliveryAddressNotes: s.deliveryAddressNotes,
                            deliveryAddressPostalCode: v.deliveryAddressPostalCode,
                            deliveryAddressIsDefault: false,
                            deliveryMethod: v.deliveryMethod,
                            deliverySignatureNotes: (_a = v.deliverySignatureNotes) === null || _a === void 0 ? void 0 : _a.trim(),
                            fulfillment: v.fulfillment,
                        });
                    },
                    workflow: workflow,
                });
            }
            if (v.fulfillment == WorkflowFulfillment.Pickup) {
                if (p.isRxOriginal) {
                    modalRxOriginal.doModal({
                        // onBack: () => {},
                        onNext: () => {
                            p.onNext({
                                fulfillment: v.fulfillment,
                            });
                        },
                    });
                }
                else {
                    p.onNext({
                        fulfillment: v.fulfillment,
                    });
                }
            }
        },
    });
    // Delivery...
    const [apiMethodList, apiMethodListState] = useLazyWorkflowDeliveryMethodViewListQuery();
    const [methodList, setMethodList] = useState([]);
    const [isMethodListFetched, setIsMethodListFetched] = useState(false);
    useEffect(() => {
        var _a;
        setMethodList(((_a = apiMethodListState.data) === null || _a === void 0 ? void 0 : _a.data.list) || []);
    }, [(_a = apiMethodListState.data) === null || _a === void 0 ? void 0 : _a.data.list]);
    const formikValues = formik.values;
    const formikPostalCode = formik.getFieldMeta("deliveryAddressPostalCode");
    const isDelivery = formikValues.fulfillment == WorkflowFulfillment.Delivery;
    const isPickup = formikValues.fulfillment == WorkflowFulfillment.Pickup;
    const isPostalCode = Boolean(formikValues.deliveryAddress.formatted);
    const isMethodList = isPostalCode && Boolean(formikValues.deliveryAddressPostalCode) && !resolveErrorTouched(formik, "deliveryAddressPostalCode");
    const isMethod = Boolean(formikValues.deliveryMethod);
    const methodDescription = getDeliveryMethodDescription(formikValues.deliveryMethod);
    const isMethodDescription = isMethodList && Boolean(methodDescription);
    const isMethodAvailability = getDeliveryMethodIsAvailability(formikValues.deliveryMethod);
    useEffect(() => {
        // TODO: reidenzon - Validation is NOT working as expected! Expected to validate upon setting value.
        formik.setFieldValue("deliveryAddressPostalCode", formikValues.deliveryAddress.postalCode || "", true);
    }, [formikValues.deliveryAddress]);
    const handleDeliveryMethods = () => __awaiter(void 0, void 0, void 0, function* () {
        // TODO: reidenzon - Skip fetch if NOT valid.
        yield apiMethodList({
            workflowGuid: workflow.guid,
            address: formikValues.deliveryAddress.formatted,
            addressPostalCode: formikValues.deliveryAddressPostalCode,
        });
        setIsMethodListFetched(true);
    });
    useEffect(() => {
        setMethodList([]);
        setIsMethodListFetched(false);
        // consoleCatch();
        handleDeliveryMethods();
    }, [formikValues.deliveryAddressPostalCode]);
    useEffect(() => {
        formik.setFieldValue("deliveryMethod", methodList[0]);
    }, [methodList]);
    useEffect(() => {
        formik.setFieldValue("deliverySignatureNotes", "");
    }, [formikValues.deliveryMethod]);
    // Pickup...
    // TODO: reidenzon - Set groupType=pharmacy flag for validation checks!
    // TODO: reidenzon - The following is debug only, do NOT merge!
    // useEffect(() => console.log("formik.touched", formik.touched), [formik.touched]);
    // useEffect(() => console.log("formik.values", formik.values), [formik.values]);
    // useEffect(() => console.log("formik.errors", formik.errors), [formik.errors]);
    // useEffect(() => console.log("formik.isValid", formik.isValid), [formik.isValid]);
    return (_jsxs(FormikProvider, Object.assign({ value: formik }, { children: [_jsx(PageOverflowContainer, Object.assign({ isFadeFooter: true }, { children: _jsx(PageWrapper, Object.assign({ isLargeVerticalPadding: true }, { children: _jsxs(Root, { children: [_jsx(JunctionBanners, { screen: p.junctionScreen }, void 0), _jsx(JunctionPopup, { screen: p.junctionScreen }, void 0), _jsx(LayoutContentHeader
                            //
                            , { 
                                //
                                isMargin: false, title: "How to fulfill your order?", description: "We can have your order ready for you to pickup at one of our pharmacies or deliver your order to you for free." }, void 0), _jsxs(FieldList, { children: [_jsx(FormikRadioButtons
                                    //
                                    , { 
                                        //
                                        formikId: "fulfillment", label: "Fulfillment", childValues: [
                                            //
                                            WorkflowFulfillment.Delivery,
                                            WorkflowFulfillment.Pickup,
                                        ].map((f) => {
                                            var _a;
                                            return ({
                                                value: f,
                                                label: (_a = getWorkflowFulfillmentInfo(f)) === null || _a === void 0 ? void 0 : _a.Text,
                                                component: null,
                                            });
                                        }), name: "fulfillment" }, void 0), isDelivery && (_jsxs(_Fragment, { children: [_jsx(LayoutContentHeader, { title: "Schedule Delivery", description: _jsx("div", { children: translate("Delivery times are dependent on location. If you need this medication urgently, please call the pharmacy directly at {telephone}.", {
                                                        telephone: _jsx(ButtonTelephone, {}, void 0),
                                                    }) }, void 0) }, void 0), _jsxs(FieldList, { children: [p.isFieldAgentPhone && (_jsx(Field, Object.assign({ title: "How can we reach you?" }, { children: _jsx(FormikTextFieldMaskedPhoneNumber, { formikId: "agentPhone", label: t("newRxFill_step3_inputLabel_phone") }, void 0) }), void 0)), _jsx(Field, Object.assign({ title: "Address Search" }, { children: _jsx(FormikAddress, { formikId: "deliveryAddress", label: "Address", placeholder: "Address" }, void 0) }), void 0), isPostalCode && (_jsx(Field, Object.assign({ title: "Postal Code" }, { children: _jsx(FormikTextField, { formikId: "deliveryAddressPostalCode", label: "Postal Code" }, void 0) }), void 0)), isMethodList && isMethodListFetched && (_jsx(Field, Object.assign({ error: resolveErrorTouched(formik, "deliveryMethod") }, { children: _jsx(DeliveryMethodSelectList, { workflow: workflow, methodList: methodList, methodSelected: formikValues.deliveryMethod, methodSelectedSet: (m) => {
                                                                formik.setFieldValue("deliveryMethod", m);
                                                            } }, void 0) }), void 0)), isMethod && p.isMethodNote && (_jsx(Field, { description: "Once your order is submitted, we'll confirm your medication is in stock and notify you when it's ready for payment." }, void 0)), isMethod && isMethodDescription && _jsx(Field, { description: methodDescription }, void 0), isMethodAvailability && (_jsx(Field, Object.assign({ title: "Signature Availability" }, { children: _jsx(FormikTextarea, { formikId: "deliverySignatureNotes", placeholder: "Mon-Tue after 5pm, etc..." }, void 0) }), void 0))] }, void 0)] }, void 0)), isPickup && (_jsxs(_Fragment, { children: [_jsx(LayoutContentHeader
                                            //
                                            , { 
                                                //
                                                title: "Pickup", description: _jsxs(_Fragment, { children: [_jsx("div", { children: "Pickup is available at the pharmacy found below during regular hours of operation." }, void 0), _jsx(AdvisoryBox
                                                        //
                                                        , { 
                                                            //
                                                            style: AdvisoryStyle.Warning, title: "You will be notified when your order is ready for pickup." }, void 0)] }, void 0), isMargin: false }, void 0), isPharmacy && _jsx(FulfillmentPharmacy, { workflow: workflow }, void 0), !isPharmacy && (_jsxs(_Fragment, { children: [_jsx(FieldError, { error: "It looks like your account isn't associated with a pharmacy yet, please start a chat with a member of our pharmacy team." }, void 0), _jsx(AssistanceLink, {}, void 0)] }, void 0))] }, void 0))] }, void 0)] }, void 0) }), void 0) }), void 0), _jsxs(Footer, { children: [_jsx(ButtonStandard, { style: ButtonStyle.SolidGhost, content: "Back", onClick: p.onBack, isWide: true }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, content: "Next", onClick: formik.handleSubmit, isSubmit: true, isWide: true }, void 0)] }, void 0)] }), void 0));
};
