import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImageTypeEnum } from "@/src/features/common/ImageUploader/types";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { t } from "@/src/features/Localization";
import { MFBButton } from "@/src/features/MFB";
import { useProfile } from "@/src/features/Profile/useProfile";
import { useCustomSnackbar } from "@/src/features/utils/CustomSnackbar";
import { FormikGenericEditField } from "@/src/features/utils/Formik/FormikGenericEditField";
import { FormikEditFieldType } from "@/src/features/utils/Formik/formikTypes";
import { JunctionBanners } from "@/src/modules/junction/components/banner/JunctionBanners";
import { JunctionPopup } from "@/src/modules/junction/components/popup/JunctionPopup";
import { JunctionScreen } from "@/src/modules/junction/type/JunctionScreen";
import { useUserEditMutation } from "@/src/modules/user/redux/ApiCoreUser";
import { addFileGuids, clearFileGuids, getFileGuids } from "@/src/redux/slices/fileSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import * as Yup from "yup";
const ProfileAccountContentContainer = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 24px;
		padding: 24px 0;
	}
`;
const EditFieldContainer = styled.div ``;
export const ProfileCoverage = () => {
    const { profileUser: user, refetchProfile } = useProfile();
    const { snackbar } = useCustomSnackbar();
    const fileGuids = useSelector(getFileGuids);
    const dispatch = useDispatch();
    const [postUserEditDetails, { error: postUserEditDetailsError, isSuccess: postUserEditDetailsSuccess }] = useUserEditMutation();
    function handleProfileInsuranceChangeSubmit(formValues) {
        const userProfileDetails = {};
        if (formValues.ProfileHealthInsuranceUpload && formValues.ProfileHealthInsuranceUpload.length > 0) {
            const healthCardData = {
                files: fileGuids.healthCard,
                isHave: true,
            };
            userProfileDetails.healthCard = healthCardData;
        }
        if (formValues.ProfileHealthInsuranceUploadCheckbox) {
            const healthCardData = {
                files: [],
                isHave: false,
            };
            userProfileDetails.healthCard = healthCardData;
            dispatch(clearFileGuids());
        }
        if (formValues.ProfilePrivateInsuranceUpload && formValues.ProfilePrivateInsuranceUpload.length > 0) {
            const healthCardData = {
                files: fileGuids.healthInsurance,
                isHave: true,
            };
            userProfileDetails.healthInsurance = healthCardData;
        }
        if (formValues.ProfilePrivateInsuranceUploadCheckbox) {
            const healthCardData = {
                files: [],
                isHave: false,
            };
            userProfileDetails.healthInsurance = healthCardData;
            dispatch(clearFileGuids());
        }
        postUserEditDetails({
            guid: user === null || user === void 0 ? void 0 : user.guid,
            details: userProfileDetails,
        });
    }
    /* -------------------------------- Variables ------------------------------- */
    const userDetails = user === null || user === void 0 ? void 0 : user.details;
    const healthCard = userDetails === null || userDetails === void 0 ? void 0 : userDetails.healthCard;
    const healthCardImages = healthCard === null || healthCard === void 0 ? void 0 : healthCard.files;
    const hasHealthCard = healthCard === null || healthCard === void 0 ? void 0 : healthCard.isHave;
    const healthInsurance = userDetails === null || userDetails === void 0 ? void 0 : userDetails.healthInsurance;
    const healthInsuranceImages = healthInsurance === null || healthInsurance === void 0 ? void 0 : healthInsurance.files;
    const hashealthInsurance = healthInsurance === null || healthInsurance === void 0 ? void 0 : healthInsurance.isHave;
    /* ------------------------------ Formik Setup ----------------------------- */
    const ProfileHealthInsuranceUploadValidationSchema = Yup.object().shape({
        ProfileHealthInsuranceUpload: Yup.array().when("ProfileHealthInsuranceUploadCheckbox", {
            is: (provincialInsuranceCheck) => provincialInsuranceCheck === false,
            then: () => Yup.array().min(1, t("profile_tab_insurance_provincialInsurance_errorMessage")).max(10, "You must provide a maximum of 10 images"),
            otherwise: () => Yup.array(),
        }),
    });
    const ProfilePrivateInsuranceUploadValidationSchema = Yup.object().shape({
        ProfilePrivateInsuranceUpload: Yup.array().when("ProfilePrivateInsuranceUploadCheckbox", {
            is: (provincialInsuranceCheck) => provincialInsuranceCheck === false,
            then: () => Yup.array().min(1, t("profile_tab_insurance_privateInsurance_errorMessage")).max(10, "You must provide a maximum of 10 images"),
            otherwise: () => Yup.array(),
        }),
    });
    /* ------------------------------- useEffects ------------------------------- */
    useEffect(() => {
        if (postUserEditDetailsSuccess) {
            refetchProfile();
            snackbar.success(t("profile_message_profileInfoSaved"));
        }
    }, [postUserEditDetailsSuccess]);
    useEffect(() => {
        if (postUserEditDetailsError) {
            snackbar.error("Could not update profile field.");
        }
    }, [postUserEditDetailsError]);
    // make sure the old guids are still there
    useEffect(() => {
        if (healthCardImages && healthCardImages.length > 0) {
            const healthCardImageGuids = healthCardImages.map((healthCardImage) => {
                return healthCardImage.guid;
            });
            dispatch(addFileGuids({
                fileGuids: healthCardImageGuids,
                fileType: ImageTypeEnum.HealthCard,
            }));
        }
        if (healthInsuranceImages && healthInsuranceImages.length > 0) {
            const healthCardInsuranceImageGuids = healthInsuranceImages.map((healthInsuranceImage) => {
                return healthInsuranceImage.guid;
            });
            dispatch(addFileGuids({
                fileGuids: healthCardInsuranceImageGuids,
                fileType: ImageTypeEnum.HealthInsurance,
            }));
        }
        return () => {
            dispatch(clearFileGuids());
        };
    }, []);
    /* ---------------------------- Render Component ---------------------------- */
    return (_jsx(PageContainer, { children: _jsxs(ProfileAccountContentContainer, { children: [_jsx(JunctionBanners, { screen: JunctionScreen.ProfileCoverage }, void 0), _jsx(JunctionPopup, { screen: JunctionScreen.ProfileCoverage }, void 0), _jsx(EditFieldContainer, { children: _jsx(FormikGenericEditField, { editFieldType: FormikEditFieldType.ImageUpload, formikId: "ProfileHealthInsuranceUpload", value: healthCardImages, label: "Health Card", helperText: t("profile_tab_insurance_description_provincialInsurance_empty"), helperTextEdit: t("profile_tab_insurance_description_provincialInsurance_edit"), imageUploadData: healthCard, imageType: ImageTypeEnum.HealthCard, checkBoxText: "Patient does not have a health card", checkBoxFormikId: "ProfileHealthInsuranceUploadCheckbox", checkBoxValue: healthCard && hasHealthCard, handleSubmit: handleProfileInsuranceChangeSubmit, validationSchema: ProfileHealthInsuranceUploadValidationSchema }, void 0) }, void 0), _jsx(EditFieldContainer, { children: _jsx(FormikGenericEditField, { editFieldType: FormikEditFieldType.ImageUpload, formikId: "ProfilePrivateInsuranceUpload", value: healthInsuranceImages, label: t("profile_tab_insurance_subheader_privateInsurance"), helperText: t("profile_tab_insurance_description_privateInsurance_empty"), helperTextEdit: t("profile_tab_insurance_description_privateInsurance_edit"), imageUploadData: healthInsurance, imageType: ImageTypeEnum.HealthInsurance, checkBoxText: "Patient does not have private insurance.", checkBoxFormikId: "ProfilePrivateInsuranceUploadCheckbox", checkBoxValue: hashealthInsurance, handleSubmit: handleProfileInsuranceChangeSubmit, validationSchema: ProfilePrivateInsuranceUploadValidationSchema }, void 0) }, void 0), _jsx(MFBButton, { saveOnValidate: true }, void 0)] }, void 0) }, void 0));
};
