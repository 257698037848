import { jsx as _jsx } from "react/jsx-runtime";
import { HtmlParser } from "@/src/common/components/html/Html";
import { JunctionServiceListItem } from "@/src/modules/junction/components/services/JunctionServiceListItem";
import { useJunctionServicesQuery } from "@/src/modules/junction/redux/ApiJunction";
import { useApiJunctionRequest } from "@/src/modules/junction/redux/ApiJunctionRequest";
import { ServiceListSection } from "@/src/modules/service/layout/service-list/ServiceListSection";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
`;
export const JunctionServiceList = (p) => {
    var _a;
    const junctionRequest = useApiJunctionRequest();
    const apiServices = useJunctionServicesQuery(junctionRequest({}));
    const serviceList = (((_a = apiServices.data) === null || _a === void 0 ? void 0 : _a.data.items) || []);
    return (_jsx(Root, { children: serviceList.map((service, index) => {
            return (_jsx(ServiceListSection
            //
            , Object.assign({ title: service.title, description: _jsx(HtmlParser, { html: service.description }, void 0) }, { children: service.cta.map((cta, index) => {
                    return _jsx(JunctionServiceListItem, { cta: cta }, cta.id);
                }) }), `${index}-${service.title}`));
        }) }, void 0));
};
